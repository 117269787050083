.marquee {
  display: inline-block;
}

.marquee span {
  opacity: 1;
  animation: hideShow 10s linear infinite;
}

@keyframes hideShow {
  0%,
  100% {
    opacity: 1;
  }

  10% {
    opacity: 0.5;
  }

  25% {
    opacity: 0.6;
  }

  40% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.8;
  }

  75% {
    opacity: 0.9;
  }
}
