/* App.css */
.link-effect:hover {
  color: white;
  /* Change color on hover */
  cursor: pointer;
  /* Change cursor to pointer to indicate it's clickable */
  text-decoration: underline;
  background-color: darkcyan;
}

/* .link-effect {
  color: #99c4c4;
  text-decoration: none;
} */
