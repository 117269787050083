/* Container */
.generate-points-container {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Title */
.title {
  text-align: start;
  margin-bottom: 30px;
}

/* Dropdowns */
.dropdown-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.dropdown-label {
  width: 150px;
  font-weight: bold;
}

.dropdown-select {
  width: 30%;
  padding: 8px;
  font-size: 16px;
}

.table-container {
  margin-top: 30px;
  max-height:  50vh;
  max-width: 155vh;
  overflow-y:auto;
  overflow-x: scroll;
  
  
}

.table-title {
  margin-bottom: 15px;
  text-align: start;
  width: 100%;
}

.runners-table {
  width: 100%;
  border-collapse: collapse;
 
}

.runners-table th,
.runners-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.runners-table th {
  background-color: #f2f2f2;
}

.runners-table tr:nth-child(even) {
  background-color: #fafafa;
}

.runners-table tr:hover {
  background-color: #eaeaea;
}
